<template>
  <section
    v-if="getCompanyDetails.form_template_view == '1'"
    class="template-dashboard-view templates-list-view bt"
  >
    <div class="inner-navbar">
      <div class="left-title">
        <div class="icon-block">
          <div class="icon" style="fill: var(--primary-color)">
            <icons :iconName="'Templates'"></icons>&nbsp;&nbsp;
          </div>
          <div class="icon-text">
            <h3
              class="title fw-normal text-dark mb-0 ls-05"
              v-if="
                this.$route.query &&
                this.$route.query.group &&
                getSingleEntityGroupData &&
                getSingleEntityGroupData.name
              "
            >
              {{ getSingleEntityGroupData.name }}
            </h3>
            <h3 class="title fw-normal text-dark mb-0 ls-05" v-else>
              Form Templates
            </h3>
          </div>
        </div>
      </div>
      <div class="right-block">
        <div
          v-if="
            selected_templates &&
            selected_templates.length &&
            getActiveWorkspace &&
            getActiveWorkspace.plan_type != 'INDIVIDUAL'
          "
        >
          <button
            class="btn btn-sm btn-outline-primary add-button float-right mr-3"
            type="button"
            @click="addToCompanyEntity"
          >
            <i class="fa fa-plus"></i> Add to company profile
          </button>
        </div>
        <div class="right-search-input">
          <div class="input-group input-group-sm search_input float-right">
            <span class="input-group-text">
              <i class="fa fa-search"></i>
            </span>
            <input
              type="text"
              class="form-control"
              placeholder="Search"
              v-model="search_string"
              @input="searchString"
            />
            <button
              class="btn btn-outline-secondary btn-sm"
              type="button"
              @click="clearSearch"
            >
              ✖
            </button>
          </div>
        </div>
        <div
          v-if="checkPermissionByPermissionName('createFormTemplate')"
          class="right-create-button"
        >
          <!-- <router-link to="/template/create"> -->
          <!-- <button
            class="btn add-button float-right mt-0"
            @click="createTemplate"
          >
            <span class="el-icon-circle-plus-outline pr-1"></span>
            Create Template
          </button> -->
          <button
            type="button"
            class="btn btn-primary btn-sm"
            @click="scratchOrAi"
          >
            <i class="fa-solid fa-square-plus mr-1"></i>
            <span>{{ getIsMobile ? "" : "Create Template" }}</span>
          </button>
          <!-- </router-link> -->
        </div>
      </div>
    </div>
    <div v-if="!getIsMobile" class="table-responsive">
      <table class="table table-hover table-sm">
        <thead class="table-header-color">
          <tr>
            <th scope="col">
              <input
                type="checkbox"
                @change="toggleSelectAll($event)"
                :checked="selectAllChecked"
              />
            </th>
            <th scope="col">Icon</th>
            <th scope="col">Name</th>
            <th scope="col">Description</th>
            <th scope="col">Created At</th>
            <th scope="col">Last Modified</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <!-- Show placeholders while loading -->
          <template v-if="loading">
            <tr v-for="n in pageSize" :key="'loading-' + n">
              <td scope="row">
                <span
                  class="placeholder placeholder-glow bg-light col-12"
                ></span>
              </td>
              <td>
                <span
                  class="placeholder placeholder-glow bg-light col-12"
                ></span>
              </td>
              <td>
                <span
                  class="placeholder placeholder-glow bg-light col-12"
                ></span>
              </td>
              <td>
                <span
                  class="placeholder placeholder-glow bg-light col-12"
                ></span>
              </td>
              <td>
                <span
                  class="placeholder placeholder-glow bg-light col-12"
                ></span>
              </td>
              <td>
                <span
                  class="placeholder placeholder-glow bg-light col-12"
                ></span>
              </td>
              <td>
                <span
                  class="placeholder placeholder-glow bg-light col-12"
                ></span>
              </td>
            </tr>
          </template>

          <!-- Show actual data once loading is false -->
          <template v-else>
            <tr v-for="row in data" :key="row._id">
              <td>
                <input
                  type="checkbox"
                  :value="row"
                  @change="toggleRowSelection(row)"
                  :checked="isSelected(row)"
                />
              </td>
              <td>
                <icons
                  :iconName="row.icon ? row.icon : 'Block-Portfolio'"
                ></icons>
              </td>
              <td>
                <span class="cursor-pointer" @click="goToEditTemplate(row._id)">
                  {{ row.name }}
                </span>
              </td>
              <td style="word-break: break-word">
                <span class="cursor-pointer" @click="goToEditTemplate(row._id)">
                  {{ row.description }}
                </span>
              </td>
              <td>
                <span class="text-muted">
                  {{ row.created_at | globalDateTimeFormat }}
                </span>
              </td>
              <td>
                <span class="text-muted">
                  {{ row.updated_at | globalDateTimeFormat }}
                </span>
              </td>
              <td>
                <div class="dropdown">
                  <button
                    class="btn btn-secondary dropdown-toggle btn-sm"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Actions
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      @click="onCustomTemplateView(row)"
                    >
                      <i class="fa fa-eye"></i> View
                    </li>
                    <li class="dropdown-item" @click="onEdit(row)">
                      <i class="fa fa-pencil"></i> Edit
                    </li>
                    <li
                      class="dropdown-item"
                      @click="openDuplicateTemplateModal(row)"
                    >
                      <i class="fa fa-clone"></i> Duplicate
                    </li>
                    <li class="dropdown-item" @click="onDelete(row)">
                      <i class="fa fa-trash"></i> Delete
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <nav>
        <ul class="pagination justify-content-center">
          <!-- Previous Button -->
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <a
              class="page-link"
              href="#"
              @click.prevent="changePage(currentPage - 1)"
              >Previous</a
            >
          </li>

          <!-- First Page -->
          <li
            v-if="currentPage > 4"
            class="page-item"
            :class="{ active: currentPage === 1 }"
          >
            <a class="page-link" href="#" @click.prevent="changePage(1)">1</a>
          </li>

          <!-- Dots before -->
          <li v-if="currentPage > 4" class="page-item disabled">
            <span class="page-link">...</span>
          </li>

          <!-- Page Numbers -->
          <li
            v-for="page in visiblePages"
            :key="page"
            class="page-item"
            :class="{ active: currentPage === page }"
          >
            <a class="page-link" href="#" @click.prevent="changePage(page)">{{
              page
            }}</a>
          </li>

          <!-- Dots after -->
          <li v-if="currentPage < totalPages - 3" class="page-item disabled">
            <span class="page-link">...</span>
          </li>

          <!-- Last Page -->
          <li
            v-if="currentPage < totalPages - 3"
            class="page-item"
            :class="{ active: currentPage === totalPages }"
          >
            <a
              class="page-link"
              href="#"
              @click.prevent="changePage(totalPages)"
              >{{ totalPages }}</a
            >
          </li>

          <!-- Next Button -->
          <li
            class="page-item"
            :class="{ disabled: currentPage === totalPages }"
          >
            <a
              class="page-link"
              href="#"
              @click.prevent="changePage(currentPage + 1)"
              >Next</a
            >
          </li>
          <li class="page-item">
            <select
              v-model="pageSize"
              @change="fetchAllCompanyTemplates"
              class="form-select form-select-md w-auto"
            >
              <option v-for="size in pageSizes" :key="size" :value="size">
                {{ size }}
              </option>
            </select>
          </li>
        </ul>
      </nav>
    </div>
    <div v-if="getIsMobile" class="inner-navbar-content top">
      <div class="vue-data-table-default" v-loading="loading">
        <data-tables-server
          :fullscreen="true"
          :data="data"
          :total="total"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          :pagination-props="paginationProps"
          @query-change="loadData"
          style="width: 100%"
          class="form-template-list-mobile"
        >
          <el-table-column type="expand">
            <template slot-scope="scope">
              <div class="expand-column-item">
                <div class="expand-column-item-label">Description:</div>
                <div class="expand-column-item-content">
                  <span :class="scope.row.status.toLowerCase() + ' circle'">
                    {{ scope.row.description }}
                  </span>
                </div>
              </div>
              <div class="expand-column-item">
                <div class="expand-column-item-label">Created At:</div>
                <div class="expand-column-item-content">
                  <template v-if="scope.row.created_at">
                    <span class="fs-8 d-inline-block pr-2 text-muted">
                      {{ scope.row.created_at | globalDateTimeFormat }}
                    </span>
                  </template>
                </div>
              </div>
              <div class="expand-column-item">
                <div class="expand-column-item-label">Last Modified:</div>
                <div class="expand-column-item-content">
                  <template v-if="scope.row.updated_at">
                    <span class="fs-8 d-inline-block pr-2 text-muted">
                      {{ scope.row.updated_at | globalDateTimeFormat }}
                    </span>
                  </template>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column id="name" width="40" fixed>
            <template id="category" slot-scope="scope">
              <span>
                <icons
                  :iconName="
                    scope.row.icon ? scope.row.icon : 'Block-Portfolio'
                  "
                ></icons>
              </span>
            </template>
          </el-table-column>

          <el-table-column
            label="Name"
            id="name"
            prop="name"
            min-width="120"
            fixed
          >
            <template v-if="scope.row.name" slot-scope="scope" id="category">
              <span
                class="cursor-pointer"
                @click="goToEditTemplate(scope.row._id)"
                >{{ scope.row.name }}</span
              >
            </template>
          </el-table-column>

          <el-table-column prop="action" min-width="105" fixed>
            <template slot-scope="scope">
              <el-dropdown>
                <span class="el-dropdown-link">
                  Actions
                  <i class="el-icon-caret-bottom el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <a
                    @click="onCustomTemplateView(scope.row)"
                    style="border: none"
                    ><el-dropdown-item
                      icon="el-icon-view"
                      command="edit"
                      :disabled="scope.row.type === 'STANDARD'"
                      >View</el-dropdown-item
                    ></a
                  >
                  <a @click="onEdit(scope.row)" style="border: none"
                    ><el-dropdown-item
                      icon="el-icon-edit-outline"
                      command="edit"
                      >Edit</el-dropdown-item
                    ></a
                  >
                  <a @click="onDelete(scope.row)" style="border: none">
                    <el-dropdown-item icon="el-icon-delete" command="delete"
                      >Delete</el-dropdown-item
                    ></a
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </data-tables-server>
      </div>
    </div>
    <div class="main-container" v-if="showLandingPagePreview">
      <div class="header-top">
        <span>Preview Landing Page</span>
        <i
          class="fa fa-times close-icon"
          @click="closeShowLandingPageDialog()"
        ></i>
      </div>
      <div class="content">
        <div
          v-loading="isLandingPageRegenate"
          :element-loading-text="loadingText"
        >
          <NewPreviewTemplate
            :name="selectedCompanyTemplate.name"
            :fieldsData="preparedData"
            :isHideFinishButton="true"
          ></NewPreviewTemplate>
        </div>
      </div>

      <div class="dialog-footer-content">
        <div class="left-content">
          <div class="response-count">
            <el-tooltip content="Previous" placement="top">
              <i
                class="el-icon-arrow-left icon-button"
                @click="showPreviousResponse"
                :class="{ 'icon-disabled': currentResponseIndex <= 0 }"
              ></i>
            </el-tooltip>
            <span class="response-text">
              {{ currentResponseIndex + 1 }} / {{ responses.length }}
            </span>
            <el-tooltip content="Next" placement="top">
              <i
                class="el-icon-arrow-right icon-button"
                @click="showNextResponse"
                :class="{
                  'icon-disabled':
                    currentResponseIndex === responses.length - 1,
                }"
                :style="{
                  cursor:
                    currentResponseIndex === responses.length - 1
                      ? 'not-allowed'
                      : 'pointer',
                }"
              ></i>
            </el-tooltip>
          </div>
          <div class="input-with-icon">
            <el-input
              v-model="landingPageRequestPrompt"
              type="textarea"
              autosize
              placeholder="Request for changes"
              class="input-field"
            ></el-input>
            <div class="icon-container">
              <el-tooltip
                v-if="!landingPageRequestPrompt"
                content="Please provide a prompt with at least 15 characters"
                placement="top"
              >
                <img
                  :src="iconSrcEnter"
                  class="suffix-icon"
                  alt="Enter Icon"
                  :style="{ cursor: 'not-allowed' }"
                  :class="'icon-disabled'"
                />
              </el-tooltip>
              <!-- Enabled state -->
              <img
                v-else
                :src="iconSrcEnter"
                class="suffix-icon"
                alt="Enter Icon"
                :style="{ cursor: 'pointer' }"
                @click="handleGenerateResponseForLandingPage(true)"
              />
            </div>
          </div>
          (or)
          <el-button
            class="regen-button"
            size="mini"
            @click="handleGenerateResponseForLandingPage()"
            :disabled="isLandingPageRegenate"
          >
            Regenerate <i class="el-icon-refresh"></i>
          </el-button>
        </div>
        <div class="right-content">
          <el-button
            type="primary"
            size="mini"
            class="continue-button"
            @click="openEditTemplateWithGeneratedResponse()"
            :disabled="isLandingPageRegenate || !preparedData.length"
          >
            Continue
          </el-button>
        </div>
      </div>
    </div>
    <dialog-component
      v-loading.fullscreen.lock="duplicateLoading"
      :title="'Duplicate Template'"
      :visible="duplicateFromTemplateDialogVisible"
      :containerWidth="'23%'"
      @before-close="closeDialog"
      v-draggable
    >
      <div class="popUp-content">
        <label>Title :</label>
        <el-input
          type="text"
          @keyup.enter.native="handleEnterKey()"
          placeholder="Title"
          v-model="duplicateTemplateName"
        >
        </el-input>
      </div>
      <span slot="footer">
        <button
          class="btn btn btn-outline-danger btn-sm m-lr-1"
          @click="closeDuplicateTemplateModal"
        >
          <i class="fa fa-close"></i> Cancel
        </button>
        <button
          class="btn btn btn-outline-primary btn-sm m-lr-1"
          @click="duplicateTemplate()"
          :disabled="!duplicateTemplateName"
        >
          <i class="fa fa-check-square-o"></i> Confirm
        </button>
      </span>
    </dialog-component>

    <dialog-component
      title="Create Template"
      :visible="dialogVisible"
      v-loading="createTemplateLoading"
      element-loading-text="Checking template Name"
      :containerMaxWidth="getIsMobile ? '80%' : '50%'"
      :containerMinHeight="getIsMobile ? '50%' : '50%'"
      @before-close="dialogVisible = false"
    >
      <div class="dialog-content">
        <el-row :gutter="20">
          <el-col :span="8">
            <div
              class="option option-card"
              @click="selectOption('template')"
              :style="{
                borderColor: selectedOption === 'template' ? '#409eff' : '',
              }"
            >
              <div class="icons plus-symbol">
                <i class="el-icon-plus"></i>
              </div>
              <div class="Title">Start from scratch</div>
              <div class="description">
                Create a new form template by simply dragging and dropping from
                a list of available fields.
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div
              class="option option-card"
              @click="selectOption('ai')"
              :style="{ borderColor: selectedOption === 'ai' ? '#409eff' : '' }"
            >
              <div class="icons star-symbol">
                <img src="@/assets/AI Icon.svg" />
              </div>
              <div class="Title">Create with AI</div>
              <div class="description">
                Provide some details, and AI will create a complete form
                template for you.
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div
              class="option option-card"
              @click="selectOption('landingPage')"
              :style="{
                borderColor: selectedOption === 'landingPage' ? '#409eff' : '',
              }"
            >
              <div class="icons star-symbol">
                <img src="@/assets/Landing Page.svg" />
              </div>
              <div class="Title">Landing Page</div>
              <div class="description">
                Quickly set up a landing page with pre-built templates and
                customization options.
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div>
        <div v-if="form_field" class="mt-1">
          <el-form>
            <el-form-item label="Form Template Name">
              <span class="text-danger">*</span>
              <el-input
                type="text"
                placeholder="Enter the form template name"
                v-model="formTemplateName"
                @keydown.enter.native.prevent="handleEnter"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div v-if="landingPageNameInput" class="mt-1">
          <el-form>
            <el-form-item label="Enter Landing Page Name">
              <span class="text-danger">*</span>
              <el-input
                type="text"
                placeholder="Enter Landing Page Name"
                v-model="formTemplateName"
                @keydown.enter.native.prevent="handleEnter"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small"
          >Cancel</el-button
        >
        <el-button
          v-if="selectedOption === 'ai' || selectedOption === 'landingPage'"
          type="primary"
          @click="createTemplate(selectedOption)"
          size="small"
          :disabled="!formTemplateName"
          >Create</el-button
        >
      </span>
    </dialog-component>
    <el-dialog title="Preview Template" :visible.sync="previewDialogVisible">
      <template>
        <PreviewTemplate
          :name="selectedCompanyTemplate.name"
          :fieldsData="selectedCompanyTemplate.fieldsData"
          v-if="previewDialogVisible"
        ></PreviewTemplate>
      </template>
    </el-dialog>
    <div class="modal-overlay" v-if="landingPageDialog">
      <div class="modal-container">
        <div class="modal-header">
          <div class="modal-title">Create with AI</div>
          <div class="modal-close">
            <i class="fa fa-times" @click="closeLandingPageDialog"></i>
          </div>
        </div>
        <div v-if="!isLandingPageGenerating">
          <div class="content-wrapper">
            <div class="image-box">
              <img src="@/assets/img/Describe.svg" />
            </div>
            <div class="text-box">
              <div class="text-title">Describe your page</div>
            </div>
            <div class="input-box">
              <label for="pageDescription"
                >Describe your page in a few words or sentences</label
              >
              <el-input
                type="textarea"
                rows="4"
                placeholder="Create a landing page for"
                v-model="landingPage_prompt"
              ></el-input>
            </div>
            <el-button
              type="primary"
              :disabled="landingPage_prompt.length < 15"
              size="mini"
              class="generate-button"
              @click="handleGenerateResponseForLandingPage()"
            >
              {{ getIsMobile ? "Generate" : "Generate Landing Page" }}
            </el-button>
          </div>
          <div class="example-text">
            (Ex: Create a landing page featuring a captivating hero section,
            testimonials, pricing options. Ensure a clear call-to-action and
            include links to social media for engagement.)
          </div>
        </div>

        <div class="loading-wrapper" v-else>
          <div class="loading-box">
            <img height="100px" width="100px" :src="iconSrc" alt="Loading..." />
            <p>Creating the magic of AI – please wait while we generate...</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section v-else>
    <TemplatesListCardView />
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import { TimelineLite, Bounce, Elastic } from "gsap/dist/gsap";
import PermissionsHelper from "@/mixins/permissionsHelper";
import NavigationHelper from "@/mixins/navigationHelper";
import TemplateBuilderHelper from "@/mixins/templateBuilderHelper";
import {
  getAllCompanyTemplatesByPagination,
  deleteTemplate,
  addSingleTemplateToLocal,
} from "../../repo/templatesRepo";
import { postAPICall } from "@/helpers/httpHelper";
export default {
  name: "templates-Templates-List",
  computed: {
    ...mapGetters("navigationOpen", [
      "getIsOpen",
      "getIsCollapse",
      "getIsMobile",
    ]),
    ...mapGetters("company", ["getCompanyDetails"]),
    ...mapGetters("companyTemplates", [
      "getAllCompanyTemplatesData",
      "getTemplateDeleteStatus",
      "getTemplateDeleteError",
      "getDuplicateFormTemplateError",
      "getDuplicateFormTemplateStatus",
    ]),
    ...mapGetters("entities", [
      "getAddEntityToCompanyStatus",
      "getCompanyEntity",
    ]),
    ...mapGetters("auth", ["getActiveWorkspace"]),

    ...mapGetters("entityGroups", ["getSingleEntityGroupData"]),
    ...mapGetters("roles", ["getRoleById", "getSuccess", "getRoleErrors"]),

    ...mapGetters("auth", ["getAuthenticatedUser"]),
    visiblePages() {
      const pages = [];

      // Determine the start and end of the visible range
      const start = Math.max(1, this.currentPage - 2);
      const end = Math.min(this.totalPages, this.currentPage + 2);

      for (let i = start; i <= end; i++) {
        pages.push(i);
      }

      return pages;
    },
    paginationProps() {
      return {
        pageSizes: this.pageSizes || [10, 20, 50],
      };
    },
    totalPages() {
      return this.total > 0 ? Math.ceil(this.total / this.pageSize) : 1;
    },
  },

  components: {
    PreviewTemplate: () => import("./PreviewTemplate"),
    TemplatesListCardView: () => import("./TemplatesListCardView.vue"),
    NewPreviewTemplate: () => import("./NewPreviewTemplate.vue"),
  },
  mixins: [PermissionsHelper, NavigationHelper, TemplateBuilderHelper],
  async mounted() {
    this.timeline = new TimelineLite({
      // onComplete: () => this.timeline.restart()
    });
    await this.fetchGroupDetails();
    this.fetchAllCompanyTemplates();
    // this.animateStatSections();
  },
  data() {
    return {
      selectAllChecked: false,
      showLandingPagePreview: false,
      iconSrc: require("@/assets/Animation.gif"),
      iconSrcEnter: require("@/assets/Enter-Active.svg"),
      generageFlag: false,
      isLandingPageGenerating: false,
      isLandingPageRegenate: false,
      loadingText: "",
      landingPageDialog: false,
      landingPageNameValidation: false,
      landingPage_prompt: "",
      landingPageRequestPrompt: "",
      preparedData: [],
      responses: [],
      currentResponseIndex: -1,
      landingPageNameInput: false,
      formTemplateId: null,
      pageSizes: [10, 20, 50],
      duplicateTemplateName: "",
      duplicateLoading: false,
      duplicateFromTemplateDialogVisible: false,
      loading: false,
      selectedOption: null,
      selectedCompanyTemplate: {},
      total: 0,
      currentPage: 1,
      pageSize: 0,
      order_type: null,
      order_by: null,
      data: [],
      previewDialogVisible: false,
      timeline: null,
      search_string: "",
      temp_total: 0,
      selected_templates: [],
      dialogVisible: false,
      AddDialogVisible: false,
      form_field: false,
      formTemplateName: "",
      createTemplateLoading: false,
    };
  },
  beforeDestroy() {
    this.$store.commit(
      "navigationOpen/setCurrentMenu",
      {},
      {
        root: true,
      }
    );

    this.$store.commit(
      "companyTemplates/setAllCompanyTemplatesData",
      {
        data: [],
      },
      {
        root: true,
      }
    );
  },
  methods: {
    toggleRowSelection(row) {
      const index = this.selected_templates.findIndex(
        (template) => template._id === row._id
      );
      if (index === -1) {
        this.selected_templates.push(row);
      } else {
        this.selected_templates.splice(index, 1);
      }
    },
    isSelected(row) {
      return this.selected_templates.some(
        (template) => template._id === row._id
      );
    },
    toggleSelectAll(event) {
      if (event.target.checked) {
        this.selected_templates = [...this.data];
      } else {
        this.selected_templates = [];
      }
      this.selectAllChecked = event.target.checked;
    },
    changePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
        this.fetchAllCompanyTemplates(); // Fetch data for new page
      }
    },
    async handleGenerateResponseForLandingPage(isLandingPageChanges = false) {
      this.loadingText = isLandingPageChanges
        ? "We are preparing your changes..."
        : "We are regenerating your landing page...";

      this.isLandingPageRegenate = true;
      try {
        await this.generateResponseForLandingPage(this, isLandingPageChanges);
      } finally {
        this.isLandingPageRegenate = false;
      }
    },
    async openEditTemplateWithGeneratedResponse() {
      try {
        if (!this.preparedData || !this.preparedData.length) {
          this.$message.error(
            "No landing page data found. Please generate the landing page first."
          );
          return;
        }
        const templateParams = {
          fields: this.preparedData,
          template_name: this.selectedCompanyTemplate.name,
          template_id: `newTemplate#${this.selectedCompanyTemplate.name}#${this.selectedCompanyTemplate.name}`,
          noTemplateCreate: true,
        };

        const templateResponse = await postAPICall(
          "POST",
          "/template/createTemplateFromJson",
          templateParams
        );
        if (templateResponse?.data?._id) {
          this.generatedTemplateId = templateResponse.data._id;
        }

        this.$router.push({
          name: "Edit Templates",
          params: { template_id: this.generatedTemplateId },
          query: {
            key: Math.floor(Math.random() * 100000),
          },
        });
      } catch (err) {
        console.error("Error navigating to edit template:", err);
        this.$message.error(
          "Failed to create the template or navigate to the edit template page."
        );
      }
    },
    closeLandingPageDialog() {
      this.landingPageDialog = false;
      let query = { ...this.$route.query };
      delete query.template_name;
      this.$router.push({ query });
      this.dialogVisible = false;
    },
    showPreviousResponse() {
      if (this.currentResponseIndex > 0) {
        this.currentResponseIndex--;
        const response = this.responses[this.currentResponseIndex];
        this.landingPageRequestPrompt = response.prompt;
        this.preparedData = response.data;
      }
    },
    showNextResponse() {
      if (this.currentResponseIndex < this.responses.length - 1) {
        this.currentResponseIndex++;
        const response = this.responses[this.currentResponseIndex];
        this.landingPageRequestPrompt = response.prompt;
        this.preparedData = response.data;
      }
    },
    closeShowLandingPageDialog() {
      this.showLandingPagePreview = false;
      let query = { ...this.$route.query };
      delete query.template_name;
      this.$router.push({ query });
      this.formTemplateName = "";
    },
    async fetchGroupDetails() {
      if (this.$route.query.group && this.$route.query.group.length) {
        await this.$store.dispatch(
          "entityGroups/fetEntityGroupDataById",
          this.$route.query.group
        );
      }
      if (this.getAuthenticatedUser && this.getAuthenticatedUser.activeRole) {
        await this.$store.dispatch(
          "roles/fetchRoleById",
          this.getAuthenticatedUser.activeRole.role_id
        );
      }
    },
    closeDialog() {
      this.duplicateFromTemplateDialogVisible = false;
    },
    scratchOrAi() {
      this.dialogVisible = true;
    },

    formOpen() {
      this.form_field = true;
    },
    landingPageValidation() {
      this.landingPageNameInput = true;
    },
    handleEnter() {
      let type = this.landingPageNameInput ? "landingPage" : "ai";
      this.createTemplate(type);
    },
    selectOption(option) {
      this.selectedOption = option;
      this.form_field = false;
      this.landingPageNameInput = false;
      if (option === "template") {
        this.createTemplate("template");
      } else if (option === "ai") {
        this.formOpen();
      } else if (option === "landingPage") {
        this.landingPageValidation();
      }
    },

    openDuplicateTemplateModal(template) {
      this.duplicateTemplateName = template.name + "-Dup";
      this.formTemplateId = template._id;
      this.duplicateFromTemplateDialogVisible = true;
    },
    closeDuplicateTemplateModal() {
      this.duplicateFromTemplateDialogVisible = false;
    },
    getTemplateSlug() {
      return this.duplicateTemplateName
        .trim()
        .toLowerCase()
        .split(" ")
        .join("-");
    },
    checkPermissions() {
      if (!this.checkPermissionByPermissionName("createFormTemplate")) {
        this.$notify.error({
          title: "Error",
          message:
            "Permission denied for create template. Please contact owner.!",
        });
      }
    },
    async addToCompanyEntity() {
      try {
        this.loading = true;
        let params = {
          templates: this.selected_templates.flatMap((e) => e._id),
        };
        await this.$store.dispatch("entities/addCompanyEntity", params);
        if (this.getAddEntityToCompanyStatus) {
          let alreadyAdded = this.selected_templates
            .filter((tmp) =>
              this.getCompanyEntity.templates
                .map((t) => t.template_id)
                .includes(tmp._id)
            )
            .map((tmp) => tmp.name);
          let message =
            `${params.templates.length - alreadyAdded.length}` +
            " templates added to company profile successfully. ";
          message += alreadyAdded.length
            ? alreadyAdded.join(", ") +
              (alreadyAdded.length > 1 ? " were" : " was") +
              " already in the company profile."
            : "";
          this.loading = false;
          this.$notify.success({
            title: "Success",
            message: message,
          });
          this.fetchAllCompanyTemplates();
        } else {
          this.loading = false;
          this.$notify.error({
            title: "Error",
            message: "Error on adding templates",
          });
        }
      } catch (er) {
        console.log("addToCompanyEntity", er);
      }
    },
    async sortChange(a) {
      this.order_by = a.prop;
      this.order_type = a.order == "ascending" ? "asc" : "desc";
      this.fetchAllCompanyTemplates();
      if (a.order == null) {
        this.order_by = null;
        this.order_type = null;
        this.fetchAllCompanyTemplates();
      }
    },
    handleSelectionChange(val) {
      this.selected_templates = [];
      this.selected_templates = val;
    },
    async fetchAllCompanyTemplates() {
      this.loading = true;
      if (this.pageSize == 0) {
        this.pageSize = 10;
      }
      let params = {
        page: this.currentPage,
        limit: this.pageSize,
      };
      if (this.order_by) {
        params.order_by = this.order_by;
      }
      if (this.order_type) {
        params.order_type = this.order_type;
      }
      if (this.$route.query.group && this.$route.query.group.length) {
        params.group = this.$route.query.group;
      }
      if (this.getAuthenticatedUser && this.getAuthenticatedUser.activeRole) {
        if (this.getRoleById && this.getRoleById) {
          params.groups = this.getRoleById.allowed_groups;
        }
      }

      if (this.search_string) {
        params.search_string = this.search_string;
      }
      // await this.$store.dispatch(
      //   "companyTemplates/getAllCompanyTemplates",
      //   params
      // );
      const getAllCompanyTemplatesData =
        await getAllCompanyTemplatesByPagination(params);

      // this.currentPage = this.getAllCompanyTemplatesData.page
      //   ? parseInt(this.getAllCompanyTemplatesData.page)
      //   : 1;
      this.total = getAllCompanyTemplatesData.total
        ? parseInt(getAllCompanyTemplatesData.total)
        : 0;
      this.data = getAllCompanyTemplatesData.data || [];
      this.loading = false;
    },
    async refreshTemplates() {
      this.currentPage = 1;
      this.pageSize = 10;
      this.fetchAllCompanyTemplates();
    },
    searchString() {
      this.pageSize = 10;
      this.page = 1;
      this.fetchAllCompanyTemplates();
    },
    clearSearch() {
      this.search_string = "";
      this.pageSize = 10;
      this.page = 1;
      this.fetchAllCompanyTemplates();
    },
    async loadData(data) {
      if (data && data.type != "init") {
        this.pageSize = data.pageSize;
        this.page = data.page;
        await this.fetchAllCompanyTemplates();
      }
    },
    async goToEditTemplate(templateId) {
      if (!this.checkPermissionByPermissionName("editFormTemplate")) {
        this.$notify.error({
          title: "Error",
          message:
            "Permission denied for edit template. Please contact owner! ",
        });
      } else {
        let query = this.getNavigationQuery(this.$route.query);
        this.$router.push({
          name: "Edit Templates",
          params: { template_id: templateId },
          query,
        });
      }
    },

    async createTemplate(type) {
      try {
        let query = this.getNavigationQuery(this.$route.query);
        if (type === "landingPage") {
          if (!this.formTemplateName.trim()) {
            return this.$message.warning(
              "Please enter a valid Landing Page Name."
            );
          }
          this.createTemplateLoading = true;
          let resp = await postAPICall(
            "POST",
            `/template/checkTemplateExists`,
            {
              template_name: this.formTemplateName,
            }
          );
          this.createTemplateLoading = false;
          if (!resp?.success) {
            return this.$message.error(
              "Landing Page Already Exists with the same name."
            );
          }
          this.$router.push({
            query: {
              template_name: this.formTemplateName,
            },
          });
          this.landingPage_prompt = `Create Landing page for ${this.formTemplateName}`;
          this.landingPageDialog = true;
          return;
        }
        if (type != "template") {
          this.createTemplateLoading = true;
          let resp = await postAPICall(
            "POST",
            `/template/checkTemplateExists`,
            {
              template_name: this.formTemplateName,
            }
          );
          this.createTemplateLoading = false;
          if (!resp?.success) {
            return this.$message.error(
              "Template Already Exists with same name"
            );
          }
        }
        let path =
          type == "template"
            ? "/template/create"
            : `/template/create-with-ai?template_name=${this.formTemplateName}`;
        this.$router.push({
          path: path,
          query,
        });
      } catch (err) {
        this.createTemplateLoading = false;
        return this.$message.error("Template Already Exists with same name");
      }
    },

    async goToViewTemplate(templateId) {
      let query = this.getNavigationQuery(this.$route.query);
      this.$router.push({
        name: "ViewTemplate",
        params: { template_id: templateId },
        query,
      });
    },

    onEdit(template) {
      if (!this.checkAppMode()) {
        return;
      }
      if (
        !this.checkPermissionByPermissionNameFormTemplates(
          template,
          "editFormTemplate"
        )
      ) {
        this.$notify.error({
          title: "Error",
          message:
            "Permission denied for edit template. Please contact owner! ",
        });
      } else {
        this.goToEditTemplate(template._id);
      }
    },
    onView(template) {
      let templateCopy = JSON.parse(JSON.stringify(template));
      this.selectedCompanyTemplate.name = "";
      this.selectedCompanyTemplate.fieldsData = [];
      this.selectedCompanyTemplate.name = templateCopy.name;

      if (templateCopy.type == "CONFIGURABLE") {
        let defaultSection = templateCopy.sections.find(
          (x) => x && x.type && x.type == "DEFAULT"
        );
        this.selectedCompanyTemplate.fieldsData = defaultSection.fields.map(
          (x) => {
            if (x.options && x.options.length) {
              x.options = x.options.join(",");
            }
            return x;
          }
        );
        this.previewDialogVisible = true;
      }
    },
    async duplicateTemplate() {
      this.duplicateLoading = true;
      let params = {
        name: this.duplicateTemplateName,
        templateId: this.formTemplateId,
        slug: this.duplicateTemplateName
          .trim()
          .toLowerCase()
          .split(" ")
          .join("-"),
      };
      try {
        const response = await postAPICall(
          "POST",
          "/template/duplicateFormTemplate",
          params
        );
        if (response?.data) {
          addSingleTemplateToLocal(response.data);
        }
        this.duplicateLoading = false;
        this.duplicateFromTemplateDialogVisible = false;
        await this.fetchAllCompanyTemplates();
      } catch (e) {
        this.$notify.error({
          title: "Error",
          message:
            e?.response?.data?.message || "Error in duplicating form template",
        });
        this.duplicateLoading = false;
      }
      // await this.$store.dispatch(
      //   "companyTemplates/duplicateFormTemplate",
      //   params
      // );
      // this.duplicateLoading = false;
      // if (this.getDuplicateFormTemplateStatus) {
      //   this.$notify.success({
      //     title: "Success",
      //     message: "Template Duplicated Successfully.",
      //   });
      //   this.duplicateFromTemplateDialogVisible = false;
      //   this.duplicateLoading = false;
      //   await this.fetchAllCompanyTemplates();
      // } else {
      //   this.$notify.error({
      //     title: "Error",
      //     message: this.getDuplicateFormTemplateError
      //       ? this.getDuplicateFormTemplateError
      //       : "Error in duplicating form template",
      //   });
      // }
    },
    onCustomTemplateView(template) {
      if (
        !this.checkPermissionByPermissionNameFormTemplates(
          template,
          "viewUserFormTemplate"
        )
      ) {
        this.$notify.error({
          title: "Error",
          message:
            "Permission denied for view template. Please contact owner! ",
        });
      } else {
        this.goToViewTemplate(template._id);
      }
    },
    async onDelete(row) {
      if (!this.checkAppMode()) {
        return;
      }
      if (
        !this.checkPermissionByPermissionNameFormTemplates(
          row,
          "deleteFormTemplate"
        )
      ) {
        this.$notify.error({
          title: "Error",
          message:
            "Permission denied for delete templates. Please contact owner.!",
        });
      } else {
        this.$confirm("Are you sure to delete the Template?", "Warning", {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }).then(() => {
          this.deleteTemplate(row);
        });
      }
    },
    async deleteTemplate(template) {
      let params = {
        id: template._id,
      };
      await this.$store.dispatch(
        "companyTemplates/deleteCompanyTemplate",
        params
      );
      if (this.getTemplateDeleteStatus) {
        this.$notify.success({
          title: "Success",
          message: "Template deleted successfully",
        });
        await deleteTemplate(template._id);
        this.refreshTemplates();
      } else {
        this.$notify.error({
          title: "Error",
          message: this.getTemplateDeleteError || "Error on deleting template",
        });
      }
    },

    animateStatSections() {
      const {
        statOne,
        statOneIcon,
        statOneContent,
        statTwo,
        statTwoIcon,
        statTwoContent,
        statThree,
        statThreeIcon,
        statThreeContent,
      } = this.$refs;

      // this.timeline.to([statOne, statTwo, statThree], 0, {
      //   autoAlpha:0
      // })

      this.timeline
        .from(statOne, 1.5, {
          autoAlpha: 0,
          scale: 1.25,
          ease: Bounce.easeOut,
        })
        .from(
          statOneContent,
          1.5,
          {
            autoAlpha: 0,
            scale: 0,
            ease: Elastic.easeInOut,
          },
          "-=1.5"
        )
        .from(
          statOneIcon,
          1.5,
          {
            opacity: 0,
            scale: 1.25,
            ease: Bounce.easeInOut,
          },
          "-=1"
        );

      this.timeline
        .from(
          statTwo,
          1.5,
          {
            autoAlpha: 0,
            scale: 1.25,
            ease: Bounce.easeOut,
          },
          "-=1"
        )
        .from(
          statTwoContent,
          1.5,
          {
            autoAlpha: 0,
            scale: 0,
            ease: Elastic.easeInOut,
          },
          "-=1.5"
        )
        .from(
          statTwoIcon,
          1.5,
          {
            opacity: 0,
            scale: 1.25,
            ease: Bounce.easeInOut,
          },
          "-=1"
        );

      this.timeline
        .from(
          statThree,
          1.5,
          {
            autoAlpha: 0,
            scale: 1.25,
            ease: Bounce.easeOut,
          },
          "-=1"
        )
        .from(
          statThreeContent,
          1.5,
          {
            autoAlpha: 0,
            scale: 0,
            ease: Elastic.easeInOut,
          },
          "-=1.5"
        )
        .from(
          statThreeIcon,
          1.5,
          {
            opacity: 0,
            scale: 1.25,
            ease: Bounce.easeInOut,
          },
          "-=1"
        );
    },
    handleEnterKey() {
      this.duplicateTemplate();
    },
  },

  watch: {
    "$route.query.group"() {
      this.fetchAllCompanyTemplates();
      this.animateStatSections();
    },
    "$route.query.key"() {
      this.fetchAllCompanyTemplates();
      this.animateStatSections();
    },
  },
};
</script>
<style lang="scss" scoped>
.main-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.2);
}

.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  font-size: 18px;
  color: white;
  font-weight: bold;
  background: var(--primary-color);
}

.close-icon {
  font-size: 20px;
  cursor: pointer;
  color: white;
  transition: color 0.3s ease;
}

.close-icon:hover {
  color: #ccc;
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: white;
  overflow-y: auto;
  scrollbar-width: thin;
  position: relative;
}

.content > * {
  flex-grow: 1;
}

.dialog-footer-content {
  display: flex;
  height: 80px;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: rgba(240, 240, 240, 1);
}

.left-content {
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
}

.response-count {
  display: flex;
  align-items: center;
  gap: 5px;
}

.response-text {
  font-size: 14px;
  color: #333;
}

.input-with-icon {
  position: relative;
}

.input-field {
  width: 300px;
  margin-left: 10px;
}
.input-with-icon .suffix-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: opacity 0.3s ease;
}
.suffix-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-left: 10px;
}

.regen-button {
  margin-left: 10px;
}

.right-content {
  display: flex;
  align-items: center;
}

.continue-button {
  font-weight: bold;
  color: #fff;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.2);
}

.modal-container {
  height: 600px;
  width: 800px;
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  width: 100%;
  background: var(--primary-color);
  color: white;
  font-size: 18px;
}

.modal-title {
  font-weight: bold;
  margin-left: 18px;
}

.modal-close {
  cursor: pointer;
  font-size: 18px;
  margin-right: 10px;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px;
  border: 1px dashed black;
  margin: 50px 80px 20px 80px;
}

.text-box {
  text-align: center;
}

.text-title {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 5px;
}

.input-box {
  width: 100%;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.generate-button {
  align-self: center;
  background-color: var(--primary-color);
  color: white !important;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.example-text {
  font-size: 14px;
  color: #777;
  text-align: center;
  margin: 0px 70px;
}

.loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}

.loading-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loading-box img {
  margin-bottom: 10px;
}

.inner-navbar-content.top {
  margin-top: -15px !important;
}
.template-dashboard-view {
  // width: 90%;
  // display: table;
  margin: 0 auto;
  max-height: 90vh !important;
  overflow-y: auto;
  scrollbar-width: thin;
  .search_input {
    // height: 29px !important;
  }
  .add-button {
    height: 32px;
    border: 1.5px solid #285ed3;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0em 0.65em;
    color: #285ed3;
    background: #ecf5ff;
    border-color: #b3d8ff;
    &:hover {
      background: #409eff;
      border-color: #409eff;
      color: #ffffff;
    }
  }
  .inner-navbar-content {
    margin-top: 20px;
  }
  .guide-stats-block {
    padding: 1em 0 2em;
    .each-stat {
      position: relative;
      background-color: #ffffff;
      border-radius: 8px;
      padding: 1em 1em 0.75em;
      color: #ffffff;
      overflow: hidden;
      min-height: 105px;
      &.stat-card-one {
        background-color: #ff83a1;
      }
      &.stat-card-two {
        background-color: #5673dc;
      }
      &.stat-card-three {
        background-color: #ffbd12;
      }
      .icon {
        position: relative;
        z-index: 3;
        img {
          max-height: 50px;
        }
      }
      .cover {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -10px;
        z-index: 1;
        img {
          width: 100%;
        }
      }
      .stat-content {
        position: relative;
        z-index: 2;
      }
      h3 {
        font-size: 3em;
        font-weight: 300;
        line-height: 1;
        text-align: center;
      }
      p {
        position: absolute;
        text-align: right;
        bottom: 0;
        right: 0;
      }
    }
    .usage-guide {
      position: relative;
      min-height: 105px;
      display: flex;
      background-color: #ebf0f3;
      border-radius: 8px;
      padding: 1em 1em 0.75em;
      overflow: hidden;
      .content {
        position: relative;
        z-index: 2;
      }
      .icon-right {
        width: 200px;
        position: relative;
        z-index: 3;
      }
      h4 {
        font-size: 1em;
        margin: 0;
        font-weight: 400;
      }
      p {
        font-size: 0.75em;
        line-height: 1.3;
        margin: 0.25em 0 0.75em;
      }
      .el-button {
        font-size: 0.85em;
        padding: 0.35em 0.75em;
        background-color: #6979f8;
        &:hover {
          background-color: #4558e9;
        }
      }

      .cover {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -10px;
        z-index: 1;
        img {
          width: 100%;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.templates-list-view {
  .el-table {
    td:first-child {
      .cell {
        text-overflow: unset !important;
      }
    }
  }

  .el-table__cell {
    padding: 5px 5px !important;
    font-size: 14px;
  }
}
</style>
<style lang="scss" scoped>
@media (min-width: 768px) {
  .left-title {
    margin-top: 10px;
  }
  .right-search-input {
    margin-top: 0px;
  }
  .right-create-button {
    margin-top: 0px;
  }
}
@media (max-width: 426px) {
  .right-search-input {
    margin-bottom: 30px !important;
    margin-left: 50px !important;
    margin-top: -30px;
    float: right !important;
  }
  .right-create-button {
    margin-bottom: 20px !important;
    float: right !important;
    margin-top: -30px;
  }
}

.inner-navbar {
  display: flex;
  justify-content: space-between;
  @media (max-width: 650px) {
    flex-direction: column;
    .left-title {
      margin-bottom: 10px;
    }
  }
  .left-title {
    .icon-block {
      display: flex;
      margin-top: 10px;
      .icon {
        width: 30px;
        margin-right: 10px;
      }
      .icon-text {
      }
    }
  }
  .right-block {
    display: flex;
    .right-search-input {
      margin-right: 10px;
      // height: 50px;
      @media (max-width: 650px) {
        margin-right: 0px;
        margin-bottom: 10px;
      }
    }
    .right-create-button {
    }
    @media (max-width: 650px) {
      align-items: end;
    }
  }
}
</style>
<style lang="scss">
.form-template-list-mobile {
  .el-table__body {
    .el-table__row {
      & > td {
        &:first-child {
          padding: 0px;
        }
      }
    }
  }
}
</style>
<style scoped>
.bt .btn-outline-primary {
  color: var(--primary-contrast-color);
  background: var(--primary-color);
  border-color: var(--primary-color);
  height: 32px !important;
  &:hover {
    color: var(--primary-contrast-color);
    background: var(--primary-color);
    border-color: var(--primary-color);
  }
  &:active:focus {
    color: var(--primary-contrast-color) !important;
    background: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
    box-shadow: 0 0 0 0.2rem var(--primary-color) !important;
  }
  @media (max-width: 767px) {
    position: relative;
    bottom: 10px;
  }
}
</style>
<style scoped>
.dialog-content {
  text-align: center;
  margin: 10px 20px 20px 20px;
}
.option {
  border: 1px solid #e4e7ed;
  border-radius: 4px;
  padding: 20px;
  cursor: pointer;
  transition: all 0.3s;
  width: 206px;
  height: 310px;
  margin-top: 10px;
}
.option-card:hover {
  transform: translateY(-5px);
  border-color: #409eff;
}
.icons {
  font-size: 36px;
  color: #409eff;
}
.el-icon-plus {
  margin-top: 40px;
}
.plus-symbol {
  background: #f5f9ff;
  width: 100%;
  height: 50%;
  border-radius: 4px;
}
.star-symbol {
  background: #f5f9ff;
  z-index: 1;
  width: 100%;
  height: 50%;
  border-radius: 4px;
}
.star-symbol img {
  margin-top: 36px;
}
.Title {
  font-size: 20px;
  margin-top: 10px;
  font-weight: bold;
  color: #303133;
  text-align: left;
}
.description {
  font-size: 12px;
  color: #303133;
  margin-top: 10px;
  text-align: left;
  line-break: normal;
  white-space: pre-line;
}

@media (max-width: 767px) {
  .option {
    height: 40%;
    width: 65%;
    margin-left: 1px;
  }
  .el-icon-plus {
    position: relative;
    bottom: 10px;
  }
  ::v-deep .star-symbol {
    height: 60%;
  }
  .option-card img {
    margin-top: 33px;
  }
  .option-card .Title {
    margin-top: 20px;
  }
  .option-card .description {
    margin-top: 47px;
  }
  .option-card {
    margin-top: 10px;
  }
}
.icon-text {
  margin-top: 10px;
}
</style>
