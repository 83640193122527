import { render, staticRenderFns } from "./TemplatesList.vue?vue&type=template&id=36c390ea&scoped=true"
import script from "./TemplatesList.vue?vue&type=script&lang=js"
export * from "./TemplatesList.vue?vue&type=script&lang=js"
import style0 from "./TemplatesList.vue?vue&type=style&index=0&id=36c390ea&prod&lang=scss&scoped=true"
import style1 from "./TemplatesList.vue?vue&type=style&index=1&id=36c390ea&prod&lang=scss"
import style2 from "./TemplatesList.vue?vue&type=style&index=2&id=36c390ea&prod&lang=scss&scoped=true"
import style3 from "./TemplatesList.vue?vue&type=style&index=3&id=36c390ea&prod&lang=scss"
import style4 from "./TemplatesList.vue?vue&type=style&index=4&id=36c390ea&prod&scoped=true&lang=css"
import style5 from "./TemplatesList.vue?vue&type=style&index=5&id=36c390ea&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36c390ea",
  null
  
)

export default component.exports